// extracted by mini-css-extract-plugin
export var close = "projects-module--close--488a5";
export var closer = "projects-module--closer--77572";
export var closest = "projects-module--closest--03bd4";
export var closest2 = "projects-module--closest2--97e3c";
export var closest3 = "projects-module--closest3--f0d84";
export var contentContainer = "projects-module--contentContainer--1ac5e";
export var descriptionBox = "projects-module--descriptionBox--f2f85";
export var descriptionContainer = "projects-module--descriptionContainer--2944d";
export var descriptionText = "projects-module--descriptionText--c79e4";
export var descriptionTitle = "projects-module--descriptionTitle--18341";
export var desktopLayout = "projects-module--desktopLayout--1a573";
export var desktopNextProject = "projects-module--desktopNextProject--f7718";
export var footie = "projects-module--footie--4a7d9";
export var imageContainer = "projects-module--imageContainer--16ad3";
export var images = "projects-module--images--5d811";
export var mainImageContainer = "projects-module--mainImageContainer--7cb52";
export var mainPage = "projects-module--mainPage--3b3df";
export var mobileLayout = "projects-module--mobileLayout--8acd9";
export var mobileNextProject = "projects-module--mobileNextProject--9d200";
export var mobileNextTitle = "projects-module--mobileNextTitle--34849";
export var nextProject = "projects-module--nextProject--a7af9";
export var nextProjectBar = "projects-module--nextProjectBar--7481a";
export var nextProjectImage = "projects-module--nextProjectImage--8c00a";
export var nextProjectNext = "projects-module--nextProjectNext--15b04";
export var nextProjectText = "projects-module--nextProjectText--d3abd";
export var nextProjectTitle = "projects-module--nextProjectTitle--b1b9f";
export var row = "projects-module--row--b72c7";
export var rowEnd = "projects-module--rowEnd--3ef7d";
export var rowStart = "projects-module--rowStart--d24b5";
export var salAnimation = "projects-module--salAnimation--f6821";
export var tall = "projects-module--tall--76afd";
export var tempMargin = "projects-module--tempMargin--d8a0c";
export var test = "projects-module--test--bbac7";
export var textContainer = "projects-module--textContainer--9e6df";
export var wide = "projects-module--wide--d8bed";